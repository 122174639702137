// core version + several modules:
import SwiperCore, { Navigation, Pagination, Autoplay, EffectFade, Controller } from 'swiper/core';
import Swiper from 'swiper';
import {getElementFromSelector} from "bootstrap/js/src/util";

// configure Swiper to use modules
SwiperCore.use([Navigation, Pagination, Autoplay, EffectFade, Controller]);

const Swipers = {}
// const customPagination = {
//     el: '.hero-slider__pagination',
//     type: 'bullets',
//     bulletClass: 'hero-slider__bullet',
//     bulletActiveClass: 'hero-slider__bullet--active',
//     clickable: true
// }
// const customNavigation = {
//     nextEl: '.hero-slider__nav-button--next',
//     prevEl: '.hero-slider__nav-button--prev',
// }

let heroSliderEl = document.querySelectorAll('.hero-slider__swiper .swiper-slide');
if (heroSliderEl.length > 1) {
    Swipers.heroSlider = new SwiperCore('.hero-slider__swiper', {
        slidesPerView: 1,
        allowTouchMove: false,
        autoHeight: false,
        loop: true,
        effect: 'slide',
        autoplay: false,
        speed: 500,
        initialSlide: 2,
        pagination: {
            el: '.hero-slider__pagination',
            type: 'bullets',
            bulletClass: 'slider__bullet',
            bulletActiveClass: 'slider__bullet--active',
            clickable: true
        },
        navigation: {
            nextEl: '.hero-slider__nav-button--next',
            prevEl: '.hero-slider__nav-button--prev',
        }
    });
}
// remove navigation and pagination if only 1 slide exists
if (heroSliderEl.length == 1) {
    let heroNav = document.getElementsByClassName('hero-slider__navigation');
    let heroPag = document.getElementsByClassName('hero-slider__pagination');
    if (heroNav[0]) {
        heroNav[0].remove();
    }
    if (heroPag[0]) {
        heroPag[0].remove();
    }
}

Swipers.articleSlider = new Swiper('.article-slider__swiper', {
    slidesPerView: 1,
    allowTouchMove: true,
    autoHeight: false,
    loop: false,
    effect: 'slide',
    autoplay: false,
    speed: 500,
    width: 248,
    initialSlide: 2,
    breakpoints: {
        768: {
            width: 350,
        },
        992: {
            width: 488,
        },
        1200: {
            width: 488,
        }
    },
    pagination: {
        el: '.article-slider__pagination',
        type: 'bullets',
        bulletClass: 'slider__bullet',
        bulletActiveClass: 'slider__bullet--active',
        clickable: true
    },
    navigation: {
        nextEl: '.article-slider__nav-button--next',
        prevEl: '.article-slider__nav-button--prev',
    }
});

Swipers.articleSliderDiscover = new Swiper('.article-slider__discover', {
    slidesPerView: 'auto',
    allowTouchMove: true,
    autoHeight: false,
    loop: true,
    effect: 'slide',
    autoplay: false,
    speed: 500,
    initialSlide: 1,
    navigation: {
        nextEl: '.article-slider__discover__nav-button--next',
        prevEl: '.article-slider__discover__nav-button--prev',
    }
});

Swipers.articleNewsSlider = new Swiper('.article-slider__news__swiper', {
    slidesPerView: 1,
    allowTouchMove: true,
    autoHeight: false,
    loop: true,
    effect: 'slide',
    autoplay: false,
    speed: 500,
    breakpoints: {
        992: {
            slidesPerView: 3,
            allowTouchMove: false,
        },
        1400: {
            slidesPerView: 4
        }
    },
    pagination: {
        el: '.article-slider__news-pagination',
        type: 'bullets',
        bulletClass: 'slider__bullet',
        bulletActiveClass: 'slider__bullet--active',
        clickable: true
    },
    navigation: {
        nextEl: '.article-slider__news__nav-button--next',
        prevEl: '.article-slider__news__nav-button--prev',
    }
});

Swipers.articleNewsSliderNarrow = new Swiper('.article-slider__news__swiper-narrow', {
    slidesPerView: 1,
    allowTouchMove: true,
    autoHeight: false,
    loop: true,
    effect: 'slide',
    autoplay: false,
    speed: 500,
    breakpoints: {
        576: {
            slidesPerView: 2,
            allowTouchMove: false,
        }
    },
    pagination: {
        el: '.article-slider__news-narrow-pagination',
        type: 'bullets',
        bulletClass: 'slider__bullet',
        bulletActiveClass: 'slider__bullet--active',
        clickable: true
    },
    navigation: {
        nextEl: '.article-slider__news-narrow__nav-button--next',
        prevEl: '.article-slider__news-narrow__nav-button--prev',
    }
});

Swipers.articleRelatedArticles = new Swiper('.article-slider__related-articles-slider', {
    slidesPerView: 1,
    allowTouchMove: true,
    autoHeight: false,
    loop: true,
    effect: 'slide',
    autoplay: false,
    speed: 500,
    breakpoints: {
        992: {
            width: null,
            slidesPerView: 3,
            allowTouchMove: false
        }
    },
    navigation: {
        nextEl: '.article-slider__related__nav-button--next',
        prevEl: '.article-slider__related__nav-button--prev',
    }
});


let searchCatEl = document.querySelectorAll('.search-results__scroll-container .swiper-slide');
if (searchCatEl.length > 1) {
    Swipers.searchCat = new SwiperCore('.search-results__scroll-container', {
        slidesPerView: 'auto',
        allowTouchMove: true,
        autoHeight: false,
        loop: false,
        effect: 'slide',
        autoplay: false,
        speed: 500,
        navigation: {
            nextEl: '.search-results__nav-button--next',
            prevEl: '.search-results__nav-button--prev',
        }
    });


    Swipers.searchCat.on('slideChange', function () {
        // console.log(this.isBeginning, this.isEnd);

        document.querySelector('.search-results__categories-container').classList.remove('search-results__categories-container--begin');
        document.querySelector('.search-results__categories-container').classList.remove('search-results__categories-container--end');
        if (this.isEnd === true) {
            document.querySelector('.search-results__categories-container').classList.add('search-results__categories-container--end');
            document.querySelector('.search-results__categories-container').classList.remove('search-results__categories-container--begin');
        }
        if (this.isBeginning === true ) {
            document.querySelector('.search-results__categories-container').classList.add('search-results__categories-container--begin');
            document.querySelector('.search-results__categories-container').classList.remove('search-results__categories-container--end');
        }
    });
}


// Swiper enabled only on mobile
// breakpoint where swiper will be destroyed
// and switches to a dual-column layout
const breakpoint = window.matchMedia( '(min-width:768px)' );
// keep track of swiper instances to destroy later

const breakpointChecker = function() {
    // if larger viewport and multi-row layout needed
    if ( breakpoint.matches === true ) {
        // clean up old instances and inline styles when available
        if ( Swipers.files !== undefined ) Swipers.files.destroy( true, true );
        // else if a small viewport and single column layout needed
        if (document.querySelectorAll('.article-slider__files-slider-in-blue .swiper-slide').length > 4) {
            document.querySelector('.article-slider--files').classList.add('article-slider--files--is-slider');
            enableFileSwiperInBlue();
        }

    } else if ( breakpoint.matches === false ) {
        // fire small viewport version of swiper
        if (document.querySelector('.article-slider__files-slider')) {
            enableFileSwiper();
        }
        if (document.querySelector('.article-slider__files-slider-in-blue')) {
            enableFileSwiperInBlue();
        }
    }
};
const enableFileSwiper = function() {
    Swipers.files = new Swiper('.article-slider__files-slider', {
        slidesPerView: 1,
        allowTouchMove: true,
        autoHeight: false,
        loop: true,
        effect: 'slide',
        autoplay: false,
        speed: 500,
        breakpoints: {
            992: {
                width: null,
                slidesPerView: 3,
                allowTouchMove: false
            }
        },
        navigation: {
            nextEl: '.article-slider__related__nav-button--next',
            prevEl: '.article-slider__related__nav-button--prev',
        }
    });
};

const enableFileSwiperInBlue = function() {
    Swipers.files = new Swiper('.article-slider__files-slider-in-blue', {
        slidesPerView: 1,
        allowTouchMove: true,
        autoHeight: false,
        loop: true,
        effect: 'slide',
        autoplay: false,
        speed: 500,
        breakpoints: {
            992: {
                slidesPerView: 3,
                allowTouchMove: false,
            },
            1400: {
                slidesPerView: 4
            }
        },
        navigation: {
            nextEl: '.article-slider__related__nav-button--next',
            prevEl: '.article-slider__related__nav-button--prev',
        }
    });
};

if (document.querySelector('.article-slider__files-slider') || document.querySelector('.article-slider__files-slider-in-blue')) {
    // keep an eye on viewport size changes
    breakpoint.addListener(breakpointChecker);
    // kickstart
    breakpointChecker();
}
