import Collapse from 'bootstrap/js/dist/collapse';
import Modal from 'bootstrap/js/dist/modal';

document.addEventListener("DOMContentLoaded", function() {

    // Close mainnav after tabbing close button
    let closeButton = document.getElementsByClassName('mainnav__close-button')[0];
    let mainnavCollapseEl = document.getElementById('mainnavCollapse');
    let mainnavCollapse = new Collapse(mainnavCollapseEl, {
        toggle: false
    });
    if (closeButton) {
        closeButton.addEventListener( 'keydown', function(e ) {
            if (e.key == 'Tab') {
                mainnavCollapse.hide();
            }
        })
    }

    let menuToggleEl = document.getElementsByClassName('header__menu-button')[0];
    let closeTextEl = document.getElementsByClassName('header__menu-button-text--close')[0];
    let closeAria = closeTextEl.getAttribute('data-aria');
    let openTextEl = document.getElementsByClassName('header__menu-button-text--open')[0];
    let openAria = openTextEl.getAttribute('data-aria');

    mainnavCollapseEl.addEventListener('shown.bs.collapse', function () {
        // console.log('when show is done');
        menuToggleEl.setAttribute('aria-label', closeAria);
    })

    mainnavCollapseEl.addEventListener('hidden.bs.collapse', function () {
        // console.log('when hidden is done');
        menuToggleEl.setAttribute('aria-label', openAria);
    })

    // Get viewport width
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

    // Menu functions and listeners for mobile view only (smaller than 768px wide)
    if (vw < 768) {
        // Toggle first level menu's by col title
        let mainMenuItems = document.getElementsByClassName('mainnav__col-title');
        Array.from(mainMenuItems).forEach(function(menuItem) {
            // Remove tabindex
            menuItem.removeAttribute('tabindex');

            menuItem.addEventListener('click', function(e) {
                e.preventDefault();
                let parent = menuItem.parentElement;
                let subMenu = parent.querySelector('.mainnav__list');
                parent.classList.toggle('mainnav__inner-col--open');
                if (subMenu) {
                    if (this.getAttribute('aria-expanded') === 'false') {
                        this.setAttribute('aria-expanded', true);
                        subMenu.setAttribute('aria-hidden', false);
                    } else {
                        this.setAttribute('aria-expanded', false);
                        subMenu.setAttribute('aria-hidden', true);
                    }
                }
            });
        });
    }

    // Toogle second level menu
    let secondLevelMenuItems = document.getElementsByClassName('mainnav__li--has-sub-list');
    Array.from(secondLevelMenuItems).forEach(function(menuItem) {

        let subList = menuItem.querySelector('.mainnav__sub-list');
        let menuItemChevron = menuItem.getElementsByClassName('mainnav__list-item-chevron');
        let menuItemAnchor = menuItem.querySelector('.mainnav__list-item');

        // Fire click on anchor
        if (menuItemAnchor) {
            menuItemAnchor.addEventListener('click', function(e) {
                e.preventDefault();
                menuItem.classList.toggle('mainnav__li--open');
                if (menuItemAnchor.getAttribute('aria-expanded') === 'false' || menuItemAnchor.getAttribute('aria-expanded') === null) {
                    menuItemAnchor.setAttribute('aria-expanded', true);
                    subList.setAttribute('aria-hidden', false);
                } else {
                    menuItemAnchor.setAttribute('aria-expanded', false);
                    subList.setAttribute('aria-hidden', true);
                }
            });

            // menuItem.addEventListener('mouseenter', function() {
            //     menuItem.classList.add('mainnav__li--open');
            // })
            // menuItem.addEventListener('mouseleave', function() {
            //     menuItem.classList.remove('mainnav__li--open');
            // })
        }
    });

    // Set focus on search input after opening search modal
    let searchModalEl = document.getElementById('searchModal')
    let searchModalObj = new Modal(searchModalEl);

    searchModalEl.addEventListener('shown.bs.modal', function (event) {
        let searchInput = searchModalEl.getElementsByClassName('search-modal__form-input');
        if (searchInput[0]) {
            searchInput[0].focus();
        }
    });

    // Close search modal on tab close button
    document.addEventListener('keyup', function(e) {
        if (e.key == 'Tab' && searchModalEl.classList.contains('show') && e.target.classList.contains('search-modal__close-button')) {
            searchModalObj.hide();
        }
    })
})
